import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {selectCurrentUser} from 'app/hooks/reduxCreateSelectorHooks';
import ExpFeatureWebInterstitial from 'app/models/experimentalFeature/ExpFeatureWebInterstitial';
import ExperimentalFeatureManager from 'app/models/experimentalFeature/ExperimentalFeatureManager';

// Michi, Basti, Simone, Sepp, Luis, Ferdi
const experimentalUserIds = [1, 438, 228061, 275832, 259300, 279510];

const useExperimental = () => {
  const {fuPaUser} = useSelector(selectCurrentUser);

  const checkAccess = () => {
    return experimentalUserIds.includes(fuPaUser?.id) || fuPaUser?.permissions?.switchEnvironment;
  };

  const [experimental, setExperimental] = useState(checkAccess);

  useEffect(() => {
    setExperimental(checkAccess);
  }, [fuPaUser]);

  return experimental;
};

const useIsGuestUser = () => {
  const {initializing, user} = useSelector(selectCurrentUser);

  const checkAccess = () => {
    return !initializing && !user?.uid;
  };
  const [isGuestUser, setIsGuestUser] = useState(checkAccess());

  useEffect(() => {
    setIsGuestUser(checkAccess());
  }, [initializing, user]);

  return isGuestUser;
};

const useWebInterstitialFlag = () => {
  const [webInterstitial, setWebInterstitial] = useState(false);

  const isGuestUser = useIsGuestUser();

  useEffect(() => {
    if (isGuestUser) {
      setWebInterstitial(true);
    } else {
      setWebInterstitial(false);
    }
  }, [isGuestUser]);

  useEffect(() => {
    const featureManager = ExperimentalFeatureManager.getInstance();
    const feature = new ExpFeatureWebInterstitial();
    const checkIsInterstitialEnabled = () => {
      setWebInterstitial(featureManager.isEnabled(feature));
    };
    featureManager.subscribe(feature, checkIsInterstitialEnabled);
    checkIsInterstitialEnabled();
  }, []);

  return webInterstitial;
};
export {useExperimental, useWebInterstitialFlag};
